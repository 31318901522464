// @ts-check
import { CardHeader, CardContent, Card } from '@mui/material';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Typography } from '../../newComponents/Typography';
import { Timeline } from '../../newComponents/Timeline';
import { TimelineItem } from '../../newComponents/TimelineItem';
import { TimelineDot } from '../../newComponents/TimelineDot';
import { TimelineSeparator } from '../../newComponents/TimelineSeparator';
import { TimelineConnector } from '../../newComponents/TimelineConnector';
import { TimelineContent } from '../../newComponents/TimelineContent';
import { IconButton } from '../../newComponents/IconButton';
import { Icon } from '../../components/Icons/Icons';
import { useScreenSize } from '../../Hooks';
import { formatDateDistance } from '../../utils/utils';

const actions = {
  REQUESTED: 'REQUESTED',
  UPLOADED: 'UPLOADED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

/** @param {import('./DocumentHistory.types').DocumentHistoryCardProps} props */
export const DocumentHistoryCard = (props) => {
  const { isMobile } = useScreenSize();
  const { document, onClose } = props;
  const { category, subcategory, history } = document;
  const sortedHistory = [...history].reverse();

  return (
    <Card elevation={0} sx={{ width: isMobile ? '100%' : '380px' }}>
      <CardHeader
        title={<Typography variant="h6">{subcategory}</Typography>}
        subheader={
          <Typography variant="body2" color="grey">
            {category}
          </Typography>
        }
        action={<IconButton icon="close_line" onClick={onClose} />}
      />
      <CardContent sx={{ maxHeight: '350px', overflowY: 'auto' }}>
        <Timeline
          data-testid="document-history-timeline"
          position="right"
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            padding: 0,
          }}
        >
          {sortedHistory.map((event, index) => {
            const fullName =
              `${event.createdBy.names} ${event.createdBy.lastNameP} ${event.createdBy.lastNameM}`.trim();

            return (
              <TimelineItem key={`event-${index}`}>
                <TimelineSeparator>
                  {event.action === actions.REQUESTED && (
                    <TimelineDot color="grey">
                      <Icon icon="send_plane_line" />
                    </TimelineDot>
                  )}
                  {event.action === actions.UPLOADED && (
                    <TimelineDot color="primary">
                      <Icon icon="user_line" />
                    </TimelineDot>
                  )}
                  {event.action === actions.APPROVED && (
                    <TimelineDot color="success">
                      <Icon icon="checkbox_circle_line" color="white" />
                    </TimelineDot>
                  )}
                  {event.action === actions.REJECTED && (
                    <TimelineDot color="error">
                      <Icon icon="close_circle_line" />
                    </TimelineDot>
                  )}
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  {event.action === actions.REQUESTED && (
                    <Typography
                      variant="subtitle2"
                      sx={{ overflowWrap: 'anywhere', width: '100%' }}
                    >
                      {`${fullName} solicitó el documento ${event.comment}`}
                    </Typography>
                  )}
                  {event.action === actions.UPLOADED && (
                    <Typography
                      variant="subtitle2"
                      sx={{ overflowWrap: 'anywhere', width: '100%' }}
                    >
                      {`${fullName} cargó el documento ${event.comment}`}
                    </Typography>
                  )}
                  {event.action === actions.APPROVED && (
                    <Typography
                      variant="subtitle2"
                      sx={{ overflowWrap: 'anywhere', width: '100%' }}
                    >
                      {`${fullName} aprobó el documento ${event.comment}`}
                    </Typography>
                  )}
                  {event.action === actions.REJECTED && (
                    <Typography
                      variant="subtitle2"
                      sx={{ overflowWrap: 'anywhere', width: '100%' }}
                    >
                      {`${fullName} rechazó el documento`}
                    </Typography>
                  )}
                  <Typography variant="caption" color="grey" display="block">
                    {formatDateDistance(new Date(event.createdAt))}
                  </Typography>
                  {event.action === actions.REJECTED && (
                    <Typography variant="caption" color="error" display="block">
                      Motivo: {event.comment}
                    </Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </CardContent>
    </Card>
  );
};
