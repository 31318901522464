import { useLazyQuery } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { useGetMyProfile } from '../../../../containers/RHPod/EmployeePortal/useGetMyProfile';
import { GET_USER_ONBOARDING_STEPS } from '../../UserOnboardingDialog/UserOnboardingDialog.gql';
import { VERIFICATION_TOKEN } from '../../IdentityVerificationDialog/IdentityVerificationDialog.constants';
import {
  resetRevokeElectronicSignatureDialog,
  revokeElectronicSignatureDialogVar,
} from '../RevokeElectronicSignatureDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useRevokeElectronicSignature() {
  const [getUserOnboardingSteps] = useLazyQuery(GET_USER_ONBOARDING_STEPS);
  const { fetchMyProfile } = useGetMyProfile();

  const handleRevokeElectronicSignature = async ({
    password,
    onIncorrectPassword,
    onSuccess,
  }: {
    password: string;
    onIncorrectPassword: () => void;
    onSuccess?: () => void;
  }) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Revocando firma electrónica...',
      });

      const response = await sifeEndpoints.revokeElectronicSignature.delete({
        data: { password },
      });

      if (response.status === 200) {
        const [{ data }] = await Promise.all([
          getUserOnboardingSteps(),
          fetchMyProfile(),
        ]);

        const onboardingStepsData = data.getUserOnboardingSteps;
        const { createElectronicSignature } = onboardingStepsData;
        if (createElectronicSignature.verificationToken) {
          localStorage.setItem(
            VERIFICATION_TOKEN,
            createElectronicSignature.verificationToken,
          );
        }

        const { onElectronicSignatureRevoked } =
          revokeElectronicSignatureDialogVar();
        onElectronicSignatureRevoked();

        resetRevokeElectronicSignatureDialog();

        if (onSuccess) {
          onSuccess();
        }

        globalSnackbarVar({
          show: true,
          message: 'Firma electrónica revocada',
          severity: 'success',
        });
        return;
      }

      if (response.status === 401) {
        onIncorrectPassword();
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al revocar la firma electrónica',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleRevokeElectronicSignature };
}
