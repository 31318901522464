import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { useGetMyProfile } from '../../../RHPod/EmployeePortal/useGetMyProfile';
import { UPDATE_CONTACT_METHODS_TYPES } from '../ProfileDialog.constants';

export type UseUpdateContactMethodsType = {
  onSuccess?: (response: any) => void;
  email?: string;
  phone?: string;
};

export function useUpdateContactMethods() {
  const { fetchMyProfile } = useGetMyProfile();

  const handleUpdateContactMethods = async (
    data: UseUpdateContactMethodsType,
  ) => {
    const { email, phone, onSuccess } = data;

    if (!email && !phone) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          'Es necesario ingresar el correo electrónico o el número de celular',
      });
      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando métodos de contacto...',
      });

      const response = await sifeEndpoints.userData.put({
        email,
        phone,
      });

      if (response.status === 200 || response?.status === 204) {
        await fetchMyProfile();

        if (onSuccess) {
          onSuccess(response);
        }
        globalSnackbarVar({
          show: true,
          message: 'Métodos de contacto actualizados con éxito',
          severity: 'success',
        });
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (UPDATE_CONTACT_METHODS_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: UPDATE_CONTACT_METHODS_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al actualizar los métodos de contacto',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleUpdateContactMethods };
}
