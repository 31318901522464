export const RESET_PASSWORD_ERROR_TYPES = {
  INVALID_CREDENTIALS: {
    message: 'La contraseña actual no es correcta',
  },
  USER_DOES_NOT_EXIST: {
    message: 'El usuario no existe',
  },
};

export const GET_CRYPTO_SIGNATURES_TYPES = {
  USER_DOES_NOT_EXIST: {
    message: 'El usuario no existe',
  },
  KEYS_MISSING: {
    message: 'No se encontró la llave pública o privada',
  },
};

export const VERIFICATION_DATA_TYPES = {
  USER_DOES_NOT_EXIST: {
    message: 'El usuario no existe',
  },
  USER_NOT_PREVIOUSLY_VERIFIED: {
    message: 'El usuario no ha sido verificado previamente',
  },
  IDENTIFICATION_NOT_AVAILABLE: {
    message: 'No se encontró la identificación',
  },
};

export const REVOKE_SIGNATURE_TYPES = {
  PASSWORD_REQUIRED: {
    message: 'Es necesario ingresar la contraseña',
  },
  INVALID_CREDENTIALS: {
    message: 'La contraseña no es correcta',
  },
  USER_DOES_NOT_EXIST: {
    message: 'El usuario no existe',
  },
  KEYS_MISSING: {
    message: 'No se encontró firma para revocar',
  },
};

export const GET_USER_DATA_TYPES = {
  USER_DOES_NOT_EXIST: {
    message: 'El usuario no existe',
  },
};

export const UPDATE_CONTACT_METHODS_TYPES = {
  USER_DOES_NOT_EXIST: {
    message: 'El usuario no existe',
  },
  EMAIL_NOT_VALID: {
    message: 'El correo electrónico no es válido',
  },
  PHONE_NOT_VALID: {
    message: 'El número de celular no es válido',
  },
  EMAIL_ALREADY_REGISTERED: {
    message: 'El correo electrónico ya está registrado',
  },
};

export const SEND_EMAIL_OR_PHONE_VERIFICATION_TYPES = {
  USER_DOES_NOT_EXIST: {
    message: 'El usuario no existe',
  },
  EMAIL_NOT_VALID: {
    message: 'El correo electrónico no es válido',
  },
  PHONE_NOT_VALID: {
    message: 'El número de celular no es válido',
  },
};
