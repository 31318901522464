import { client } from '..';
import {
  currentCompanyVar,
  currentRoleVar,
  filesVar,
  globalDownloadSnackbarVar,
  isLoggedInVar,
  pendingDocsVar,
  userCompaniesVar,
  userPermissionsVar,
  userRolesVar,
  userVar,
} from '../cache.reactiveVars';
import { VERIFICATION_TOKEN } from '../businessComponents/Dialogs/IdentityVerificationDialog/IdentityVerificationDialog.constants';
import { resetFiltersDashboard } from '../containers/RHPod/Dashboard/Dashboard.vars';
import { resetSendMassiveOnboardingNotificationDialogVar } from '../containers/RHPod/Employees/Employees.vars';
import { resetUserOnboardingVar } from '../containers/Landing/UserOnboarding/UserOnboarding.vars';
import { clearAllTrackedIntervals } from './time.utils';
import { resetAllFilterTemplates } from '../containers/MyCompany/Settings/Preferences/Templates/Templates.vars';
import { createDocumentResetFilters } from '../containers/RHPod/Documents/CreateDocuments/CreateDocumentDialog.vars';

const logout = (history, options) => {
  client?.clearStore();
  globalDownloadSnackbarVar({
    downloads: [],
  });
  localStorage.removeItem('token');
  localStorage.removeItem('remainingTime');
  localStorage.removeItem('token_sife');
  isLoggedInVar(false);
  localStorage.removeItem('currentCompany');
  currentCompanyVar(null);
  localStorage.removeItem('userCompanies');
  userCompaniesVar(null);
  localStorage.removeItem('currentRole');
  currentRoleVar(null);
  localStorage.removeItem('userRoles');
  userRolesVar(null);
  localStorage.removeItem('currentUser');
  localStorage.removeItem('user');
  userVar(null);
  localStorage.removeItem('pendingDocs');
  pendingDocsVar(null);
  localStorage.removeItem('soraAdmin');
  localStorage.removeItem('notShowPendingReceipts');
  userPermissionsVar(null);
  resetFiltersDashboard();
  filesVar([]);
  localStorage.removeItem('hideOnboardingWorkflow');
  localStorage.removeItem(VERIFICATION_TOKEN);
  resetSendMassiveOnboardingNotificationDialogVar();
  resetUserOnboardingVar();
  resetAllFilterTemplates();
  createDocumentResetFilters();

  let loginRoute = '/login';
  if (options?.search) {
    loginRoute += `?${options.search}`;
  }
  history && history.push(loginRoute);
  clearAllTrackedIntervals();
};

export default logout;
