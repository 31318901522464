export const ID_TYPES = {
  INE: 'INE',
  PASSPORT: 'PASSPORT',
  OTHER: 'OTHER',
};

export const VERIFICATION_TYPES = {
  CRYPTO_SIGNATURE_CREATION: 'CRYPTO_SIGNATURE_CREATION',
};

export const VERIFICATION_ERROR_TYPES = {
  ID_CARD_EXPIRED: 'ID_CARD_EXPIRED',
  ID_NOT_VALID_CURP_NOT_FOUND_IN_RENAPO:
    'ID_NOT_VALID_CURP_NOT_FOUND_IN_RENAPO',
  ID_NOT_VALID_IN_INE_DATABASE: 'ID_NOT_VALID_IN_INE_DATABASE',
};

export const VERIFICATION_ERROR_MESSAGES = {
  [VERIFICATION_ERROR_TYPES.ID_CARD_EXPIRED]:
    'La identificación que intentas verificar ha expirado.',
  [VERIFICATION_ERROR_TYPES.ID_NOT_VALID_CURP_NOT_FOUND_IN_RENAPO]:
    'No fue posible validar tu CURP con la base de datos de RENAPO.',
  [VERIFICATION_ERROR_TYPES.ID_NOT_VALID_IN_INE_DATABASE]:
    'No fue posible validar tu credencial con la base de datos del INE.',
  FACE_NOT_DETECTED:
    'Por favor, vuelve a intentar, procura que haya buena iluminación y que solo se capture tu rostro.',
  GENERIC:
    'Por favor, vuelve a intentar, verifica que el texto sea legible y que la identificación corresponda a la que elegiste.',
  OUT_OF_SERVICE:
    'Verificación de identificación fuera de servicio, por favor intente más tarde.',
};

export const VERIFICATION_TOKEN = 'VERIFICATION_TOKEN';
