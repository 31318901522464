// @ts-check
import React, { useState } from 'react';
import { IconButton } from '../../newComponents/IconButton';
import { DocumentHistory } from './DocumentHistory';

/** @param {import('./DocumentHistory.types').DocumentHistoryButtonProps} props */
export const DocumentHistoryButton = (props) => {
  const [historyAnchorEl, setHistoryAnchorEl] = useState(
    /** @type {HTMLElement | null} */ (null),
  );

  /** @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event */
  const handleShowHistory = (event) => {
    setHistoryAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        icon="discuss_fill"
        onClick={handleShowHistory}
        aria-label="Ver historial del documento"
      />
      <DocumentHistory
        anchorEl={historyAnchorEl}
        setAnchorEl={setHistoryAnchorEl}
        document={props.document}
      />
    </>
  );
};
