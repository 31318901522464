import { Grid, InputAdornment, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ControlledEmailInput } from '../../../../businessComponents/ControlledInputs';
import { useForm } from 'react-hook-form';
import { ControlledPhoneInput } from '../../../../components/ControlledInputs';
import type { ProfileContactMethodsProps } from '../ProfileDialog.types';
import { openVerifyContactMethodDialogVar } from '../ProfileDialog.vars';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { useUpdateContactMethods } from '../Hooks/useUpdateContactMethods';
import { VerifyContactMethodDialog } from './VerifyContactMethodDialog';
import { useSendEmailOrSmsVerification } from '../Hooks/useSendEmailOrSmsVerification';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { Icon } from '../../../../components/Icons/Icons';
import { Tooltip } from '../../../../newComponents/Tooltip';

const ContactMethodsForm = {
  email: '',
  phone: '',
};

const RenderWarningAdornment = ({ title = '' }) => (
  <InputAdornment position="end">
    <Tooltip title={title} placement="top" arrow>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Icon icon="error_warning_line" color="warning" height="24px" />
      </Stack>
    </Tooltip>
  </InputAdornment>
);

export const ProfileContactMethods = (props: ProfileContactMethodsProps) => {
  const { user, refetch } = props;
  const [disableContactMethods, setDisableContactMethods] = useState(true);
  const [updatedData, setUpdatedData] = useState({ email: '', phone: '' });

  const { handleUpdateContactMethods } = useUpdateContactMethods();
  const { handleSendEmailVerification, handleSendSmsVerification } =
    useSendEmailOrSmsVerification();

  const personalDataForm = useForm({
    mode: 'onChange',
    defaultValues: ContactMethodsForm,
  });

  const { control } = personalDataForm;
  const [contactMethodRequired, setContactMethodRequired] = useState(false);

  const [emailWatch, phoneWatch] = personalDataForm.watch(['email', 'phone']);

  const emailVerified = user?.contactVerification?.email?.verified;
  const tmpEmail = user?.contactVerification?.email?.tmpValue;

  const phoneVerified = user?.contactVerification?.phone?.verified;
  const tmpPhone = user?.contactVerification?.phone?.tmpValue;

  const currentEmail = !emailVerified && tmpEmail ? tmpEmail : user?.email;
  const currentPhone = !phoneVerified && tmpPhone ? tmpPhone : user?.phone;
  const emailIsNotVerified = !emailVerified && tmpEmail;
  const phoneIsNotVerified = !phoneVerified && tmpPhone;

  const handleOpenVerificationDialog = () => {
    openVerifyContactMethodDialogVar({ open: true });
  };

  const handleSubmit = (values) => {
    const dataToUpdate = {
      email: '',
      phone: '',
    };

    const currUser = { email: currentEmail, phone: currentPhone };

    if (currUser.email !== values.email) {
      dataToUpdate.email = values.email;
    }

    if (currUser.phone !== values.phone) {
      dataToUpdate.phone = values.phone;
    }

    handleUpdateContactMethods({
      ...dataToUpdate,
      onSuccess: (response) => {
        if (refetch) {
          refetch();
          setDisableContactMethods(true);
        }

        const status = response?.data?.statusCode;

        if (status === 201 && dataToUpdate.email) {
          setUpdatedData({ phone: '', email: dataToUpdate.email });
          handleOpenVerificationDialog();
          return;
        }

        if (status === 202 && dataToUpdate.phone) {
          setUpdatedData({ email: '', phone: dataToUpdate.phone });
          handleOpenVerificationDialog();
          return;
        }

        if (status === 203) {
          setUpdatedData(dataToUpdate);
          handleOpenVerificationDialog();
          return;
        }
      },
    });
  };

  const handleSubmitError = () => {
    globalSnackbarVar({
      show: true,
      message: 'Falta información requerida',
      severity: 'error',
    });
  };

  const handleSubmitEmailVerification = () => {
    handleSendEmailVerification({
      email: currentEmail,
    });
  };

  const handleSubmitSmsVerification = () => {
    handleSendSmsVerification({
      phone: currentPhone,
    });
  };

  useEffect(() => {
    if (!user) return;
    personalDataForm.reset({
      email: currentEmail,
      phone: currentPhone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setContactMethodRequired(!emailWatch && !phoneWatch);
  }, [emailWatch, phoneWatch]);

  const formIsDirty = personalDataForm.formState.isDirty;

  return (
    <form
      style={{ width: '100%', height: '100%' }}
      onSubmit={personalDataForm.handleSubmit(handleSubmit, handleSubmitError)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ControlledEmailInput
            control={control}
            rules={{
              required: {
                value: contactMethodRequired,
                message: 'Correo electrónico o celular requerido',
              },
            }}
            textFieldProps={{
              label: 'Correo electrónico',
              required: contactMethodRequired,
              fullWidth: true,
              size: 'small',
              disabled: disableContactMethods,
              ...(emailIsNotVerified && {
                InputProps: {
                  endAdornment: (
                    <RenderWarningAdornment title="Debes verificar tu correo" />
                  ),
                },
              }),
            }}
          />
          {emailIsNotVerified ? (
            <Stack direction="row" alignItems="center" spacing={1} mt={1}>
              <Typography variant="caption" color="text.secondary">
                Pendiente de verificación
              </Typography>
              <Typography
                variant="caption"
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={handleSubmitEmailVerification}
              >
                ¿Volver a enviar enlace?
              </Typography>
            </Stack>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledPhoneInput
            control={control}
            rules={{
              required: {
                value: contactMethodRequired,
                message: 'Correo electrónico o celular requerido',
              },
            }}
            mergeWithDefaultRules
            muiProps={{
              label: 'Celular',
              required: contactMethodRequired,
              fullWidth: true,
              size: 'small',
              disabled: disableContactMethods,
              ...(phoneIsNotVerified && {
                InputProps: {
                  endAdornment: (
                    <RenderWarningAdornment title="Debes verificar tu número de telefono" />
                  ),
                },
              }),
            }}
          />
          {phoneIsNotVerified ? (
            <Stack direction="row" alignItems="center" spacing={1} mt={1}>
              <Typography variant="caption" color="text.secondary">
                Pendiente de verificación
              </Typography>
              <Typography
                variant="caption"
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={handleSubmitSmsVerification}
              >
                ¿Volver a enviar código?
              </Typography>
            </Stack>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
        mt={2}
      >
        <Button
          variant="outlined"
          color="default"
          onClick={() => setDisableContactMethods(!disableContactMethods)}
        >
          {disableContactMethods ? 'Editar' : 'Cancelar'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disableContactMethods || !formIsDirty}
          type="submit"
        >
          Actualizar
        </Button>
      </Stack>
      <VerifyContactMethodDialog updatedData={updatedData} />
    </form>
  );
};
