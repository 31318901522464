import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { SEND_EMAIL_OR_PHONE_VERIFICATION_TYPES } from '../ProfileDialog.constants';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';

export type UseSendEmailOrSmsVerification = {
  onSuccess?: () => void;
  email?: string;
  phone?: string;
};

export function useSendEmailOrSmsVerification() {
  const handleSendEmailVerification = async (
    data: UseSendEmailOrSmsVerification,
  ) => {
    const { email, onSuccess } = data;

    if (!email) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Es necesario ingresar el correo electrónico',
      });
      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Enviando código de verificación...',
      });

      const response = await sifeEndpoints.sendEmailVerification.post({
        email,
      });

      if (response.status === 200 || response?.status === 201) {
        if (onSuccess) {
          onSuccess();
        }
        globalSnackbarVar({
          show: true,
          message: 'Código de verificación enviado con éxito',
          severity: 'success',
        });
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (SEND_EMAIL_OR_PHONE_VERIFICATION_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: SEND_EMAIL_OR_PHONE_VERIFICATION_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al enviar el código de verificación',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleSendSmsVerification = async (
    data: UseSendEmailOrSmsVerification,
  ) => {
    const { phone, onSuccess } = data;

    if (!phone) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Es necesario ingresar el número de celular',
      });
      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Enviando código de verificación...',
      });

      const response = await sifeEndpoints.sendPhoneVeritication.post({
        phone,
      });

      if (response.status === 200 || response?.status === 201) {
        if (onSuccess) {
          onSuccess();
        }
        globalSnackbarVar({
          show: true,
          message: 'Código de verificación enviado con éxito',
          severity: 'success',
        });
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (SEND_EMAIL_OR_PHONE_VERIFICATION_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: SEND_EMAIL_OR_PHONE_VERIFICATION_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al enviar el código de verificación',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleSendEmailVerification, handleSendSmsVerification };
}
